
import { ref } from 'vue';
import { deleteRole, getRoleList, getRoles, addRole, getSelectMenuTree, updateRole, getMenuIdsByRoleId } from '@/api/role';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';

export default {
    setup(){
        const roleFormVisible = ref(false);

        const validate: any = ref(null);

        const loading = ref(false);

        const store = useStore();

        const formSize = ref('large');

        const dialogTitle = ref('');

        const linkage = ref(false);

        const formRules = {
            roleName: [
            {
                required: true,
                message: '请输入角色名',
            }
            ],
            roleChar: [
            {
                required: true,
                message: '请输入角色字符',
            }
            ],
        };


        const getLockedDict = (locked) => {
            let label = '';
            let type = '';
            if(locked){
                label = '禁用';
                type = 'warning';
            }else{
                label = '启用';
                type = 'success';
            }
            return {"label": label,"type": type};
        };


        const roleLocked = [
            {
                locked: true,
                label: '禁用',
            },
            {
                locked: false,
                label: '启用',
            }
        ];
        //适配移动端
        if(store.state.app.isMobile){
            formSize.value = 'mini';
        }else{
            formSize.value = 'large';
        }

        const table:any = ref({
            page: 1,        // 当前在第几页
            pageSize: 10,   // 一页显示多少
            total: 0,       // 数据总量
            loading: false,  // 加载中
            data: [],       // 表格数据
            isMobile: false,// 表格是否移动端
        });

        const searchParams: any = ref({
            roleName: '',
            roleChar: '', 
            locked: '',
        });

        const roleForm: any = ref({
          id: 0,
          roleName: '',
          roleChar: '',
          menuIds: [],
          locked: 0,
        });

        const menuOptions = ref();

        const menuIds: any = ref([]);

        table.value.isMobile = store.state.app.isMobile;


        //获取角色列表
        const listRoles = () => {
            table.value.loading = true,
            getRoleList({
                page: table.value.page,
                pageSize: table.value.pageSize,
            }).then((res: any) => {
                table.value.data = res.data.list;
                table.value.total = res.data.total;
                table.value.loading = false;
            });
        }

        const handleAddRole = () => {
          roleFormVisible.value = true;
          dialogTitle.value = '添加角色';
          roleForm.value = {};
          menuIds.value.setCheckedKeys([]);
        }

        //获取菜单树
        getSelectMenuTree().then((res: any) => {
            menuOptions.value = res.data;
        });

        listRoles();

        const roles = ref([]);

        const handleSizeChange = (pageSize: number) => {
            table.value.pageSize = pageSize;
            listRoles();
        }

        const handleCurrentChange = (page: number) => {
            table.value.page = page;
            listRoles();
        }

        const searchRoles = () => {
            table.value.loading = true,
            getRoleList({
                page: table.value.page,
                pageSize: table.value.pageSize,
                roleName: searchParams.value.roleName,
                roleChar: searchParams.value.roleChar,
                locked: searchParams.value.locked,
            }).then((res: any) => {
                table.value.data = res.data.list;
                table.value.total = res.data.total;
                table.value.loading = false;
            });
        }

        const handleDelete = (id) => {
            deleteRole(id).then((res: any) => {
                if(!res.errCode) {
                    ElMessage.success("删除成功!");
                    listRoles();
                }else{
                    ElMessage.error(res.detail);
                }
            });
        }


        const handleEditClick = (row) => {
            const formData = JSON.parse(JSON.stringify(row)); //消除row的响应性
            dialogTitle.value = '修改角色';
            loading.value = true;
            getMenuIdsByRoleId(row.id).then((res: any) => {
                if(!res.errCode) {
                    menuIds.value.setCheckedKeys(res.data);
                    loading.value = false;
                }else {
                    ElMessage.error(res.detail);
                    loading.value = false;
                }
            });
            roleForm.value = formData;
            roleFormVisible.value = true;
        }

        const onCancel = () => {
            roleFormVisible.value = false;
        }

        const submitForm = () => {
            let menuIdArr = [];
            menuIdArr = menuIds.value.getCheckedKeys().concat(menuIds.value.getHalfCheckedKeys());
            roleForm.value.menuIds = menuIdArr;
            if(roleForm.value.id) {
                loading.value = true;
                updateRole(roleForm.value).then((res: any) => {
                    if(!res.errCode){
                        ElMessage.success('更新角色成功!');
                        roleFormVisible.value = false;
                        roleForm.value = {};
                        menuIds.value.setCheckedKeys([]);
                        listRoles();
                    }else{
                        ElMessage.error(res.detail);
                    }
                    loading.value = false;
                }).catch(() => {
                    loading.value = false;
                });
            }else{
                let menuIdArr = [];
                menuIdArr = menuIds.value.getCheckedKeys().concat(menuIds.value.getHalfCheckedKeys());
                roleForm.value.menuIds = menuIdArr;
                validate.value.validate((valid) => {
                if(valid) {
                    loading.value = true;
                    addRole(roleForm.value).then((res: any) => {
                        if(!res.errCode){
                            ElMessage.success('新建角色成功!');
                            roleFormVisible.value = false;
                            roleForm.value = {};
                            menuIds.value.setCheckedKeys([]);
                            listRoles();
                        }else{
                            ElMessage.error(res.detail);
                        }
                        loading.value = false;
                    }).catch(() => {
                        loading.value = false;
                    });
                }
            });
            }
            
        }

        
        return {
            table,
            handleSizeChange,
            handleCurrentChange,
            searchParams,
            searchRoles,
            formSize,
            roleLocked,
            getLockedDict,
            handleDelete,
            handleEditClick,
            submitForm,
            roleFormVisible,
            roleForm,
            roles,
            loading,
            formRules,
            validate,
            menuOptions,
            menuIds,
            dialogTitle,
            onCancel,
            handleAddRole,
            linkage,
            };
        }
}
